import React from "react"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

const submisionsPage = () => (
  <div className="body">
    <Layout>
      <SEO title="Submissions" />
      <div data-w-id="63b8498e-1639-aebb-9ce4-f79485e68895" className="page">
        <section className="section">
          <div className="page-heading-parent">
            <h1>Share Your Work.</h1>
            <div className="line grey"></div>
          </div>
          <h2>
            Submit to <em>the Pub!</em>
          </h2>
          <div className="w-richtext">
            <p>
              Submissions to our Spring 2025 issue <em>Dichotomy</em> are currently closed. Please check back in the spring for when our Fall 2025 submission period opens!
            </p>
          </div>
          <div className="rich-text-block w-richtext">
            <p>‍</p>
            <h2>Submissions Guidelines:</h2>
            <p>
              <em>Remember: </em>you don&#x27;t have to be a student to submit
              to <em>The Pub! </em>If you are an alumni, professor, or staff
              member at Wheaton, we would be honored to receive your work.
              <br></br>
              ***ALL PIECES MUST INCLUDE CONTENT WARNINGS if they deal with topics
              including but not limited to: death, self-harm, suicide, mental illness, sexual
              harassment, sexual assault, other sexual content, body horror, etc. Pieces that espouse
              hate in any form will not be considered.***
            </p>
            <br></br>
            <h4>
              ESSAY | 2500 word limit | Chicago Manual of Style with endnotes | Citations required
            </h4>
            <p>
              Essays include critical essays, reviews, and interviews. For the Essays
              section, you can either submit a complete essay for editing, or submit a
              500-word essay proposal which will be coached and edited by our Essays
              editors throughout the semester.  The first option is best if you already
              have a piece written, and it only needs minor edits for publication. The
              second option is best if you have an idea you’ve wanted to carry out for a
              while and The Pub looks like a good reason to do it!<br></br> For the
              former, the submission process is normal: submit before the deadline, and
              you will be contacted about edits and copyediting if accepted. For the
              latter, essay proposals should be submitted at least a week before the
              deadline and will be reviewed by the Editorial team. If accepted, you will
              work with the Essays section over the next two weeks on your piece. This is
              a great choice if you want experience in a collaborative editing process.
              Normally, we would have more time to accomplish this option, but this
              semester has constrained our timetable. You are welcome to submit more than
              one piece, but <strong>only one essay will be published per
              individual.</strong>
            </p>
            <br></br>
            <h4>
              NARRATIVE | 3500 word limit
            </h4>
            <p>
              Narrative accepts both fiction and non-fiction storytelling. You are welcome
              to submit more than one piece, but <strong>only one will be published per
              individual.</strong>
            </p>
            <br></br>
            <h4>POETRY | No word limit</h4>
            <p>
              Poetry is always a popular section of The Pub! Please make sure your
              document is editable and includes your name at the top. You can submit up to
              5 pieces, though <strong>our maximum for publication is 2 per
              person.</strong>
            </p>
            <br></br>
            <h4>
              VISUAL ART | Highest image quality possible | Artist statement: 250 word limit
            </h4>
            <p>
              All mediums of visual artwork accepted. You may submit a standalone piece or
              a series. We believe art to be an essential part of intellectual Christian
              discussion. In order to spark discussion, we require artists to submit a
              statement to accompany their work. This statement might be a short piece of
              creative writing, an explanation of your process, an exploration of the
              subject matter, a relation of it to the issue’s themes, etc. It does not
              need to be a stiff or explanatory statement–we trust your work speaks for
              itself. We welcome the collaboration of essayists and artists in the
              exploration of a piece. Please make sure you have submitted your highest
              quality image and your statement is in an editable Google Doc. You may
              submit more than one image, but <strong>typically only two spreads (4 pages)
              will be devoted to one artist.</strong>
              <a href="https://wheatonpub.netlify.app/submissions#">
                <br />
              </a>
            </p>
          </div>
        </section>
      </div>
    </Layout>
  </div>
)
export default submisionsPage
